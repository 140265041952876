import React, { createContext, useContext, useMemo } from "react";

const UserContext = createContext(null);

export const UserProvider = ({ currentUser, children }) => {
  const value = useMemo(() => {
    const isRespondologyUser = () => {
      return currentUser && typeof currentUser.is_respo_user !== "undefined"
        ? currentUser.is_respo_user
        : false;
    };

    return {
      currentUser,
      isRespondologyUser,
    };
  }, [currentUser]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useCurrentUser = () => {
  return useContext(UserContext);
};
