import React, { createContext, useContext } from "react";

const EntitlementContext = createContext(null);

export const EntitlementProvider = ({ entitlements, children }) => {
  return (
    <EntitlementContext.Provider value={entitlements || []}>
      {children}
    </EntitlementContext.Provider>
  );
};

export const useEntitlements = () => {
  return useContext(EntitlementContext);
};
